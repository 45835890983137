import React,{Component} from 'react';
import {Text,ScrollView,View} from 'react-native'
import {answerEvaluActions,testevalActions} from '../../../actions'
import {connect} from 'react-redux'
import {Loader,QestionElment,SubmitBtn,CheckElement,CheckIcon,WaitingSvg,CostumTopbar} from '../_Common'
import {Colors} from '../../../config'
import {LoaderFake} from '../_Common/LoaderFake';

class AnswerEvaluTest extends Component {
    constructor(props) {
        super(props)
        this.state={
            show: 1,
            nbPage: 10
        }
        this.answers=null
        this.qestions=[]
        this.scrollBack=React.createRef()
        this._handleNext=this._handleNext.bind(this)
        this._handleSubmit=this._handleSubmit.bind(this)
        this._handleAnswers=this._handleAnswers.bind(this)
        this._handleStore=this._handleStore.bind(this)
        props.getDetailTest(props.match.params.idtest)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {

        const {getingDetailEval,dataDetailEval,anwercandsaved,getingTestEvalfake}=nextProps
        if(this.props.getingTestEvalfake===0&&getingTestEvalfake===2&&anwercandsaved&&Array.isArray(anwercandsaved.questions)) {
            this.answers=anwercandsaved.questions
            this.qestions=anwercandsaved.questions
        } else {
            if(this.props.getingDetailEval===0&&getingDetailEval===1&&dataDetailEval&&Array.isArray(dataDetailEval.qestions)) {
                this.answers=dataDetailEval.qestions
                this.qestions=dataDetailEval.qestions
                setTimeout(() => {
                    this.props.fakerefresh()
                },500);
            }
        }
    }
    componentDidMount() {

    }
    _handleAnswers(value) {
        this.answers[value.index]=value.qst
    }
    _handleNext(value) {
        this.scrollBack.scrollTo({x: 0,y: 0})
        let index=this.state.show
        this.setState({
            show: (index+value)
        })
        this.scrollBack.scrollTo({x: 0,y: 0})
    }
    _handleSubmit() {
        let answers={test_connaissance_id: this.props.dataDetailEval.test_connaissance_id,questions: this.answers}
        this.props.submitAnswer(answers)
    }
    _handleStore() {
        console.log('respose saved /')
        setTimeout(() => {
            let answers={
                test_connaissance_id: this.props.dataDetailEval.test_connaissance_id,
                questions: this.answers
            }
            this.props.storeCondidateTest({
                test_connaissance_id: this.props.dataDetailEval.test_connaissance_id,
                data: JSON.stringify(answers),
                fullname: this.props.dataUser.user.firstname+'_'+this.props.dataUser.user.lastname
            })
        },1000);
    }
    render() {
        const {getingDetailEval,submitinAnswer,dataDetailEval,getingTestEvalfake}=this.props
        var qestion=[]
        var end=0
        var start=0
        if(this.qestions) {
            start=(this.state.show*this.state.nbPage)-this.state.nbPage
            end=this.state.show*this.state.nbPage
            qestion=this.qestions.slice(start,end).map((qst,key) => {
                return <QestionElment key={key} index={key+start} qst={qst} handleAnswer={this._handleAnswers} handleStore={this._handleStore}/>
            })
        }
        return (
            <>
                <CostumTopbar title="Test de connaissance" goBack={this.props.history.goBack} />
                <View style={{flex: 1,height: "100%"}}>{
                    getingDetailEval===0? <Loader />:
                        getingTestEvalfake===0? <LoaderFake />:
                            <>
                                {submitinAnswer===0&&<Loader />}
                                {dataDetailEval.message==="Test not started"?
                                    <CheckElement Icon={<WaitingSvg width="110" height="110" />}>
                                        <Text style={{fontSize: 20,fontWeight: "700",textAlign: "center"}}>Vous recevrez une notification de la part de L’Industreet lorsque le test commencera ☺️</Text>
                                    </CheckElement>:dataDetailEval.message?
                                        <CheckElement Icon={<CheckIcon width="110" height="110" />}>
                                            <Text style={{fontSize: 20,fontWeight: "700",textAlign: "center"}}>Votre test de connaissances est terminé ! Bravo ! ☺️</Text>
                                        </CheckElement>:

                                        <ScrollView showsVerticalScrollIndicator={false} ref={ref => (this.scrollBack=ref)}>
                                            <>
                                                <View style={{paddingTop: 12}}></View>
                                                {qestion}
                                                {this.qestions.length>end?
                                                    <View style={{flexDirection: "row",justifyContent: "space-around"}}>
                                                        {start>0&&<SubmitBtn actionPress={() => this._handleNext(-1)} text="Precedent" bdgClr="#2D70F320" fontClr={Colors.primary} />}
                                                        <SubmitBtn actionPress={() => this._handleNext(1)} text="Suivant" bdgClr={Colors.primary} fontClr="#FFF" />
                                                    </View>:
                                                    <View style={{flexDirection: "row",justifyContent: "space-around"}}>
                                                        {start>0&&<SubmitBtn actionPress={() => this._handleNext(-1)} text="Retour" bdgClr="#2D70F320" fontClr={Colors.primary} />}
                                                        <View style={{marginBottom:15,marginTop:10}}>
                                                            <SubmitBtn actionPress={this._handleSubmit} text="Envoyer" bdgClr={Colors.primary} fontClr="#FFF" />
                                                        </View>
                                                    </View>
                                                }</>
                                        </ScrollView>}</>}
                </View>
            </>
        )
    }
}
function mapState(state) {
    const {getingDetailEval,dataDetailEval,submitinAnswer,dataSubmit,anwercandsaved,getingTestEvalfake}=state.testeval
    const {dataUser}=state.auth

    return {getingDetailEval,dataDetailEval,submitinAnswer,dataSubmit,dataUser,anwercandsaved,getingTestEvalfake}
}
const actionCreators={
    initAnswer: answerEvaluActions.initAnswer,
    getDetailTest: testevalActions.getDetailTest,
    storeCondidateTest: testevalActions.storeCondidateTest,
    fakerefresh: testevalActions.fakerefresh,
    submitAnswer: testevalActions.submitAnswer
};

const connectedClass=connect(mapState,actionCreators)(AnswerEvaluTest);
export {connectedClass as AnswerEvaluTest};