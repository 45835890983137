import {messagingConstants} from "../constants";

export function messaging(state,action) {
    switch(action.type) {
        case messagingConstants.LISTEN_CHANNEL_SUCCESS_TWO:
            return {
                ...state,
                listenchnnelstatetwho: 1
            }
        case messagingConstants.LISTEN_CHANNEL_SUCCESS:
            return {
                ...state,
                listenchnnelstate: 1
            }
        case messagingConstants.SYNC_MESSAGES_CHANNEL_REQESUT:
            return {
                ...state,
                syncMessageChannel: 0
            }
        case messagingConstants.SYNC_MESSAGES_CHANNEL_SUCCESS:
            const { messages = [] } = action.payload
            return {
                ...state,
                syncMessageChannel: 1,
                messagesChannel: messages
            }
        case messagingConstants.SYNC_MESSAGES_CHANNEL_FAILEUR:
            return {
                ...state,
                syncMessageChannel: 2
            }
        /******************* */
        case messagingConstants.SYNC_MESSAGES_NOTIFICATION_CHANNEL_SUCCESS:
            if(action.payload.notifcationchannelsallmy>0){                
                var audio = new Audio('/sounds/notfication.mp3');
                console.log('new msgs 000000000000000000000000000000000000000000000000000')
                audio.play();
            }

            return {
                ...state,
                syncMessagenotificationChannel: 1,
                messagesChannelnofiticationall: action.payload.notifcationchannelsallmy
            }
            case messagingConstants.SYNC_MESSAGE_NOTIFICATIONS_CHANNEL_FAILEUR:
            return {
                ...state,
                syncMessagenotificationChannel: 0
            }

        case messagingConstants.SET_MESSAGES_CHANNEL:
            const {message={}}=action.payload
            return {
                ...state,
                messagesChannel: [...state.messagesChannel,message]
            }

        default:
            return {
                messagesChannel: [],
                ...state
            }
    }
}