export const messagingConstants = {

    LISTEN_CHANNEL_SUCCESS: "LISTEN_CHANNEL_SUCCESS",
    LISTEN_CHANNEL_SUCCESS_TWO: "LISTEN_CHANNEL_SUCCESS_TWO",

    SYNC_MESSAGES_CHANNEL_REQESUT: "SYNC_MESSAGES_CHANNEL_REQESUT",
    SYNC_MESSAGES_CHANNEL_SUCCESS: "SYNC_MESSAGES_CHANNEL_SUCCESS",
    SYNC_MESSAGES_CHANNEL_FAILEUR: "SYNC_MESSAGES_CHANNEL_FAILEUR",

    SYNC_MESSAGES_NOTIFICATION_CHANNEL_SUCCESS: "SYNC_MESSAGES_NOTIFICATION_CHANNEL_SUCCESS",
    SYNC_MESSAGE_NOTIFICATIONS_CHANNEL_FAILEUR: "SYNC_MESSAGES_NOTIFICATION_CHANNEL_FAILEUR",

    SET_MESSAGES_CHANNEL: "SET_MESSAGES_CHANNEL"
};