import React,{Component} from 'react'
import {Text,View,Image,TextInput} from 'react-native';
import {CheckBox,RadioBox} from './Buttons'
import {connect} from 'react-redux'
import {styles} from './stylesQestions'
class QestionElment extends Component {
    constructor(props) {
        super(props)
        this.state={
            qestion: this.props.qst,
            index: this.props.index,
            onfocustext: '',
        }
        this.timeout=0;
        this._handleAnswer=this._handleAnswer.bind(this)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if(this.props.index!==nextProps.index) {
            this.setState({
                qestion: nextProps.qst,
                index: nextProps.index
            })
        }
    }
    _handleAnswer(answer) {
        let qestion=this.state.qestion
        switch(answer.type) {
            case "vraifaux":
                qestion.answer[0].answer=answer.value
                break;
            case "libre":
                qestion.answer[0].answer=answer.value
                if(this.timeout) {
                    clearTimeout(this.timeout)
                };
                if(this.state.onfocustext!==answer.value) {
                    this.timeout=setTimeout(() => {
                        this.setState({...this.state,onfocustext: answer.value})
                        this.props.handleStore()
                    },10000);
                }

                break;
            case "QCM":
                qestion.answer[answer.detailIndex].answer=answer.value
                break;
            case "Class":
                qestion.answer[answer.detailIndex].answer=answer.value
                if(this.timeout) {
                    clearTimeout(this.timeout)
                };
                if(this.state.onfocustext!==answer.value) {
                    this.timeout=setTimeout(() => {
                        this.setState({...this.state,onfocustext: answer.value})
                        this.props.handleStore()
                    },10000);
                }

                break;
            default:
                break;
        }
        this.setState({
            qestions: qestion
        })
        this.props.handleAnswer({qst: qestion,index: this.state.index})
    }
    handleQestion() {
        switch(this.state.qestion.typequestion) {
            case "vraifaux":
                return <View style={styles.spaceAround}>
                    <View>
                        <View style={styles.alignItem}>
                            <RadioBox actionPress={this._handleAnswer} secondactionPress={this.props.handleStore} valueReturn={{type: "vraifaux",value: 1}} checked={this.state.qestion.answer&&this.state.qestion.answer[0]&&this.state.qestion.answer[0].answer===1} />
                            <Text style={styles.titleOption}>Oui</Text>
                        </View>
                    </View>
                    <View>
                        <View style={styles.alignItem}>
                            <RadioBox actionPress={this._handleAnswer} secondactionPress={this.props.handleStore} valueReturn={{type: "vraifaux",value: 0}} checked={this.state.qestion.answer&&this.state.qestion.answer[0]&&this.state.qestion.answer[0].answer===0} />
                            <Text style={styles.titleOption}>Non</Text>
                        </View>
                    </View>
                </View>
            case "QCM":
                return <View>
                    {this.state.qestion.answer.map((detail,key) => {
                        return <View key={key} style={styles.alignFlex}>
                            <CheckBox actionPress={this._handleAnswer} secondactionPress={this.props.handleStore} valueReturn={{type: "QCM",value: detail.answer===1? null:1,detailIndex: key}} checked={detail.answer===1} />
                            <View style={{width: "90%"}}>
                                <Text multiline={true} style={styles.titleOption}>{detail.qsttext}</Text>
                            </View>
                        </View>
                    })}
                </View>
            case "Class":
                return <View>
                    {this.state.qestion.answer.map((detail,key) => {
                        return <View style={{marginBottom: 10,marginLeft: 0}} key={key}>
                            <Text style={styles.titleOption}>{detail.qsttext}</Text>
                            <View>
                                <TextInput
                                    onChangeText={text => this._handleAnswer({type: "Class",value: text,detailIndex: key})}
                                    value={detail.answer}
                                    style={{borderColor: "#2D70F370",fontSize: 16,marginTop: 10,paddingHorizontal: 10,borderWidth: 2,borderRadius: 8,paddingVertical: 10}}
                                    placeholder="Renseignez dans l’ordre en utilisant les chiffres..."
                                    keyboardType='numeric'
                                    onFocus={(e) => this.setState({...this.state,onfocustext: e.nativeEvent.text})}
                                    onBlur={(e) => {
                                        if(this.state.onfocustext!==e.nativeEvent.text) {
                                            if(this.timeout) {
                                                clearTimeout(this.timeout)
                                            }
                                            this.props.handleStore()
                                        }
                                    }} />
                            </View>
                        </View>
                    })}
                </View>
            default: return <TextInput
                onChangeText={text => this._handleAnswer({type: "libre",value: text})}
                value={this.state.qestion.answer&&this.state.qestion.answer[0]? this.state.qestion.answer[0].answer:""}
                style={styles.textArea}
                placeholder="Decriver votre reponse sur le qestion"
                multiline={true}
                numberOfLines={10}
                onFocus={(e) => this.setState({...this.state,onfocustext: e.nativeEvent.text})}
                onBlur={(e) => {
                    if(this.state.onfocustext!==e.nativeEvent.text) {
                        if(this.timeout) {
                            clearTimeout(this.timeout)
                        }
                        this.props.handleStore()
                    }
                }} />
        }
    }
    render() {
        return (
            <View style={styles.container}>
                <Text style={styles.Title}>{(this.props.index+1)+"-  "+this.state.qestion.textqst}</Text>
                {this.state.qestion.image&&<img
                    src={this.state.qestion.image}
                    alt=''
                    style={{
                        resizeMode: 'contain',
                        aspectRatio: 1.5,
                        marginBottom: 10,
                        borderRadius: 5
                    }}
                />}
                {this.handleQestion()}
            </View>
        )
    }
}
function mapState(state) {
    return {}
}
const actionCreators={
};

const connectedClass=connect(mapState,actionCreators)(QestionElment);
export {connectedClass as QestionElment};