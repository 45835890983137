import { messagingConstants } from '../constants';
import { FunctionService, RequestService } from '../services';
export const messagingActions = {
    GetMessages,
    SendMessage,
    GetMessagesNotification,
    readallmasgsactivechannelcondidate,
    GetNotificationstatecheck,
    GetNotificationstatechecktwo,
    AddMessage
}
function GetMessages() {
    return dispatch => {
        dispatch(FunctionService.request(messagingConstants.SYNC_MESSAGES_CHANNEL_REQESUT))
        RequestService.GetRequest(`messages/me`, true)
            .then(({ data = {} }) => {
                dispatch(FunctionService.succes(data, messagingConstants.SYNC_MESSAGES_CHANNEL_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err, messagingConstants.SYNC_MESSAGES_CHANNEL_FAILEUR))
            })
    }
}
function GetNotificationstatecheck() {
    // console.log('-0-000000000000000000-0-0-0-0-0-0-0-0-')
    return dispatch => {
        dispatch(FunctionService.request(messagingConstants.LISTEN_CHANNEL_SUCCESS))
    }
}
function GetNotificationstatechecktwo() {
    // console.log('-0-000000000000000000-0-0-0-0-0-0-0-0-')
    return dispatch => {
        dispatch(FunctionService.request(messagingConstants.LISTEN_CHANNEL_SUCCESS_TWO))
    }
}
function readallmasgsactivechannelcondidate(message) {
    return dispatch => {
        RequestService.PostRequest(`messages/readreadmy`,{idchannel:message},true)
        .then(({data={}}) => {
                console.log('readallmasgsactivechannelcondidate is : yes')
            })
            .catch(err => {
                console.log('readallmasgsactivechannelcondidate is : No')
            })
    }
}
function GetMessagesNotification() {
    return dispatch => {
        RequestService.GetRequest(`messages/me-notification`, true)
            .then(({ data = {} }) => {
                console.log('8888888888888  GetMessagesNotification : ', data)
                dispatch(FunctionService.succes(data, messagingConstants.SYNC_MESSAGES_NOTIFICATION_CHANNEL_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err, messagingConstants.SYNC_MESSAGE_NOTIFICATIONS_CHANNEL_FAILEUR))
            })
    }
}

function SendMessage(message) {
    return dispatch => {
        dispatch(FunctionService.request(messagingConstants.SYNC_MESSAGES_CHANNEL_REQESUT))
        RequestService.PostRequest(`messages/send`, message, true)
            .then(({ data = {} }) => {
                dispatch(FunctionService.succes(data, messagingConstants.SYNC_MESSAGES_CHANNEL_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err, messagingConstants.SYNC_MESSAGES_CHANNEL_FAILEUR))
            })
    }
}
function AddMessage(message) {
    return dispatch => {
        dispatch(FunctionService.succes({ message }, messagingConstants.SET_MESSAGES_CHANNEL))
    }
}