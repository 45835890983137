import React from 'react'
import { View, ActivityIndicator, Text } from 'react-native'
import { Colors } from '../../../config'
export const LoaderFake = (props) => {
    return (
        <View style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            minHeight: 100,
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            zIndex: 100,
            backgroundColor: "#00000020"
        }}>
            <ActivityIndicator size={70} color={Colors.primary} />
            <Text
            style={{
                fontSize:17,
                fontWeight:'700',
                marginTop:30,
                textAlign:'center',
                paddingHorizontal:50,
                paddingVertical:10
            }}
            >Merci de patienter durant le chargement de votre test.</Text>
        </View>
    )
}
