import React from 'react'
import {HomeSvg,EvaluSvg,AutoSvg,MoreSvg} from './IconsSvg'
import {View,StyleSheet,TouchableOpacity,Text} from 'react-native'
import {useHistory,useLocation} from "react-router-dom";
import {Colors} from '../../../config'
import {connect} from "react-redux";
import {messagingActions} from "../../../actions";


const styles=StyleSheet.create({
    container: {
        width: "100%",
        height: 62,
        backgroundColor: "#ffffff",
        shadowColor: "#000000",
        shadowOffset: {
            width: 5,
            height: 20,
        },
        shadowOpacity: 0.7,
        shadowRadius: 10,
        elevation: 10,
    },
    flexView: {
        height: "100%",
        flexDirection: 'row',
        justifyContent: "space-around",
        alignItems: 'center',
    },
    elementBar: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
    },
    textAction: {
        fontWeight: "700"
    },
    msgnotificationView: {
        position: 'absolute',
        right: `-15px`,
        borderColor: 'red',
        backgroundColor: 'red',
        borderRadius: '50%',
        width: '28px',
        height: '28px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
        borderBottomLeftRadius: 0
    },
    msgnotification: {
        color: 'white',
        fontSize: 16
    }
});
const CostumTabBottum=(props) => {
    const {messagesChannelnofiticationall}=props

    const navigation=useHistory();
    const route=useLocation()
    
    React.useEffect(() => {
        setTimeout(() => {
            
            // console.log('bye bye here 99988 =',props.listenchnnelstate)
            if(props.listenchnnelstate===1) {
                console.log('No need to listen again ----------------------====88')
            } else {
                props.GetMessagesNotification()
                props.GetNotificationstatecheck()
                console.log('We are listening ----------------------====88')
                window.Echo.channel(`messaging-adminsJta`)
                    .listen('RecordChannelEvent',(e) => {
                        if(e.asyncList==="asyncforcond") {
                            console.log('66666666666666666666666666666666666666')
                            props.GetMessagesNotification()
                        }
                    })
            }
        },200);
    },[])

    return (
        <View style={styles.container}>
            <View style={styles.flexView}>
                <TouchableOpacity onPress={() => navigation.push('/Home')}>
                    <View style={styles.elementBar}>
                        <HomeSvg color={route.pathname==="/Home"? Colors.primary:Colors.iconColor} />
                        <Text style={[styles.textAction,{color: route.pathname==="/Home"? Colors.primary:Colors.iconColor}]}>Accueil</Text>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => navigation.push('/EvaluTest')}>
                    <View style={styles.elementBar}>
                        <EvaluSvg color={route.pathname==="/EvaluTest"? Colors.primary:Colors.iconColor} />
                        <Text style={[styles.textAction,{color: route.pathname==="/EvaluTest"? Colors.primary:Colors.iconColor,marginTop: 6}]}>Tests</Text>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => navigation.push('/AutoTest')}>
                    <View style={styles.elementBar}>
                        <AutoSvg color={route.pathname==="/AutoTest"? Colors.primary:Colors.iconColor} />
                        <Text style={[styles.textAction,{color: route.pathname==="/AutoTest"? Colors.primary:Colors.iconColor,marginTop: 3}]}>Honnêteté</Text>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {navigation.push('/More')}}>
                    <View style={styles.elementBar} >
                        <MoreSvg color={route.pathname==="/More"? Colors.primary:Colors.iconColor} />
                        <Text style={[styles.textAction,{color: route.pathname==="/More"? Colors.primary:Colors.iconColor}]}>Plus</Text>
                        {messagesChannelnofiticationall>0&&
                            <View style={styles.msgnotificationView}>
                                <Text style={styles.msgnotification}>{messagesChannelnofiticationall}</Text>
                            </View>
                        }
                    </View>
                </TouchableOpacity>
            </View>
        </View>
    )
}
function mapState(state) {
    const {messagesChannelnofiticationall,listenchnnelstate}=state.messaging
    return {messagesChannelnofiticationall,listenchnnelstate}
}
const connectedClass=connect(mapState,messagingActions)(CostumTabBottum);
export {connectedClass as CostumTabBottum};
