import React,{Component} from "react";
import {ScrollView,Text,TextInput,TouchableHighlight,View} from "react-native";
import {connect} from "react-redux";
import {messagingActions} from "../../../actions";
import {Colors} from "../../../config";
import {CostumTopbar,Loader,SendMessageSvg} from "../_Common";
import {MessageItem,styles} from "./utils";

class Messaging extends Component {
    state={
        message: ""
    }
    scroleBottom=false
    refMessage=React.createRef()
    componentDidMount() {
        this.fetchMessages()
        const channelpusher=window.Echo.channel(`messaging-${this.props.dataUser.user.socket_channel}`);
        channelpusher.listen('MessagingEvent',(e) => {
            if(e.message&&e.source) {
                console.log(' -*++++ jjjjjjjjjjjjjijijjijijijijjijijijjjjjjijijijijijijijijijijijijijij @ ',this.props.dataUser.channeltoupdate)
                if (this.props.dataUser.channeltoupdate === 'none') {
                    window.location.reload()
                } else {
                    if (window.location.pathname==='/Question') {
                        console.log('okay okay okay okay')
                        this.props.AddMessage(e.message)
                        this.props.readallmasgsactivechannelcondidate(this.props.dataUser.channeltoupdate)
                        var audiomsg=new Audio('/sounds/newmsg.mp3');
                        audiomsg.play();
                        this.scrollToBottom()
                        this.scroleBottom=true
                    }                    
                    // this.props.GetMessagesNotification()
                }
            }
        })
        // console.log('bye bye here 99988two =',this.props.listenchnnelstatetwho)
        // if(this.props.listenchnnelstatetwho===1) {
        // } else {
        //     this.props.GetNotificationstatechecktwo()
        //     console.log('We are listening two ----------------------====88')
        //     window.Echo.channel(`messaging-${this.props.dataUser.user.socket_channel}`)
        //         .listen('MessagingEvent',(e) => {
        //             if(e.message&&e.source) {
        //                 this.props.AddMessage(e.message)
        //                 // this.scrollToBottom()
        //                 setTimeout(() => {

        //                     console.log('No need 11111111111111111111111111111111111111111111')
        //                     this.scroleBottom=true
        //                 }, 500);
        //                 // await this.props.readallmasgsactivechannelcondidate(this.props.dataUser.channeltoupdate)
        //                 // this.props.GetMessagesNotification()
        //             }
        //         })
        // }
        // },200);

    }
    componentWillUnmount() {
        // if(window.Echo.channel(`messaging-${this.props.dataUser.user.socket_channel}`)) {
        // const channelpusher=window.Echo.channel(`messaging-${this.props.dataUser.user.socket_channel}`);
        // channelpusher.stopListening('MessagingEvent')
        window.Echo.leaveChannel(`messaging-${this.props.dataUser.user.socket_channel}`);
        // }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.syncMessageChannel===0&&this.props.syncMessageChannel===1) {
            this.scrollToBottom()
        }
        if(this.scroleBottom) {
            console.log('No need 2222222222222222222222222222222222 iiii')
            this.scrollToBottom()
            this.scroleBottom=false
        }
    }
    fetchMessages=async () => {
        await this.props.GetMessages()
        await this.props.GetMessagesNotification()
    }
    setMessageState=(text) => {
        this.setState({
            message: text
        })
    }
    submitMessage=() => {
        if(!this.state.message) return
        this.props.SendMessage({message: this.state.message})
        this.state.message=""
    }
    scrollToBottom=() => {
        if(this.refMessage&&this.refMessage.current) {
            this.refMessage.current.scrollIntoView({behavior: 'smooth'})
        }
    }
    render() {
        const {syncMessageChannel,messagesChannel,messagesChannelnofiticationall}=this.props
        return (
            <>
                <CostumTopbar title={"Boîte à questions"} goBack={() => this.props.history.push("/More")} />
                {syncMessageChannel===0&&<Loader />}
                <ScrollView showsVerticalScrollIndicator={false} style={{paddingHorizontal: 7,paddingVertical: 15}}>
                    {messagesChannel.map(({message_text,is_me}={},key) => {
                        return <MessageItem key={key} message={message_text} isMe={is_me} />
                    })}
                    <View ref={this.refMessage}></View>
                </ScrollView>
                <View style={styles.sendMessage}>
                    <TextInput
                        onChangeText={this.setMessageState}
                        value={this.state.message}
                        style={styles.inputMessage}
                        placeholder="Écrire votre question ..."
                    />
                    <TouchableHighlight onPress={this.submitMessage}>
                        <SendMessageSvg width={25} height={25} color={Colors.primary} />
                    </TouchableHighlight>
                </View>

            </>
        )
    }
}
function mapState(state) {
    const {syncMessageChannel,messagesChannel,messagesChannelnofiticationall,listenchnnelstatetwho}=state.messaging
    const {dataUser}=state.auth
    return {syncMessageChannel,messagesChannel,dataUser,messagesChannelnofiticationall,listenchnnelstatetwho}
}
const connectedClass=connect(mapState,messagingActions)(Messaging);
export {connectedClass as Messaging};