import { testevalConstants } from '../constants'
export function testeval(state, action) {
    switch (action.type) {
        case testevalConstants.SUBMIT_TEST_REQUEST_START:
            return {
                ...state,
                getingTestEvalfake: 0,
            }
        case testevalConstants.SUBMIT_TEST_REQUEST_END:
            return {
                ...state,
                getingTestEvalfake: 2,
            }
        case testevalConstants.GET_TEST_REQUEST:
            return {
                ...state,
                getingTestEval: 0,
                dataTestEval: action.payload
            }
        case testevalConstants.GET_TEST_SUCCESS:
            return {
                ...state,
                getingTestEval: 1,
                dataTestEval: action.payload
            }
        case testevalConstants.GET_TEST_FAILURE:
            return {
                ...state,
                getingTestEval: 2,
                dataTestEval: action.payload
            }
        case testevalConstants.GET_DETAIL_TEST_REQUEST:
            return {
                ...state,
                getingDetailEval: 0
            }
        case testevalConstants.GET_DETAIL_TEST_SUCCESS:
            let result = {}
            if (action.payload) {
                if (action.payload.typeAnswer === "qst") {
                    let test = action.payload
                    if (test && test.message) {
                        result.id_jta = test.message.jta_evaluation_id
                        result.qestions = []
                        if (test.message.test_connais) {
                            result.test_connaissance_id = test.message.test_connais.test_connaissance_id
                            if (test.message.test_connais.grid && Array.isArray(test.message.test_connais.grid.questions)) {
                                test.message.test_connais.grid.questions.map(qst => {
                                    let resltpush = { question_id: qst.question_id, textqst: qst.text, typequestion: qst.typequestion, image: qst.image, answer: [] }
                                    Array.isArray(qst.details) && qst.details.map(detail => {
                                        resltpush.answer.push({ detail_question_id: detail.detail_question_id, answer: qst.typequestion === "QCM" ? 0 : null, qsttext: detail.detailtext })
                                    })
                                    result.qestions.push(resltpush)
                                })
                            }
                        }
                    }
                } else {
                    result = action.payload
                }
            }
            return {
                ...state,
                getingDetailEval: 1,
                anwercandsaved: action.payload.anwercandsaved,
                dataDetailEval: result
            }
        case testevalConstants.GET_DETAIL_TEST_FAILURE:
            return {
                ...state,
                getingDetailEval: 2,
                dataDetailEval: action.payload
            }
        case testevalConstants.SUBMIT_TEST_REQUEST:
            return {
                ...state,
                submitinAnswer: 0,
                dataSubmit: action.payload
            }
        case testevalConstants.SUBMIT_TEST_SUCCESS:
            let dataReturn = {}
            if (action.payload && action.payload.message === "sucessfully") {
                dataReturn = {
                    dataDetailEval: { message: action.payload.note }
                }
            } else {
                dataReturn = {
                    dataSubmit: action.payload
                }
            }
            return {
                ...state,
                submitinAnswer: 1,
                ...dataReturn
            }
        case testevalConstants.SUBMIT_TEST_FAILURE:
            return {
                ...state,
                submitinAnswer: 2,
                dataSubmit: action.payload
            }
        default:
            return {
                dataDetailEval: {},
                ...state
            }
    }
}