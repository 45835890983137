import { testevalConstants } from '../constants';
import { RequestService,FunctionService } from '../services';
export const testevalActions = {
    getTest,
    getDetailTest,
    storeCondidateTest,
    fakerefresh,
    submitAnswer
};
function getTest(){
    return dispatch => {
        dispatch(FunctionService.request(testevalConstants.GET_TEST_REQUEST))
        RequestService.GetRequest('candidate/testsConnaissance',true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,testevalConstants.GET_TEST_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,testevalConstants.GET_TEST_FAILURE))
        })
    }
}
function getDetailTest(idtest){
    return dispatch => {
        dispatch(FunctionService.request(testevalConstants.GET_DETAIL_TEST_REQUEST))
        RequestService.GetRequest('candidate/testsConnaissance/detail/'+idtest,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,testevalConstants.GET_DETAIL_TEST_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,testevalConstants.GET_DETAIL_TEST_FAILURE))
        })
    }
}
function submitAnswer(answer){
    return dispatch => {
        dispatch(FunctionService.request(testevalConstants.SUBMIT_TEST_REQUEST))
        RequestService.PostRequest("answerCand/create",answer,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,testevalConstants.SUBMIT_TEST_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,testevalConstants.SUBMIT_TEST_FAILURE))
        })
    }
}
function storeCondidateTest(answer){
    return dispatch => {
        // dispatch(FunctionService.request(testevalConstants.SUBMIT_TEST_REQUEST))
        RequestService.PostRequest("candidate/testsConnaissance/save",answer,true)
        .then(res=>{
            console.log('Answers are saved ')
            // dispatch(FunctionService.succes(res.data,testevalConstants.SUBMIT_TEST_SUCCESS))
        })
        .catch(err=>{
            console.log('Answers are not saved ')
            // // dispatch(FunctionService.failure(err.response,testevalConstants.SUBMIT_TEST_FAILURE))
        })
    }
}
function fakerefresh(){
    return dispatch => {
        dispatch(FunctionService.request(testevalConstants.SUBMIT_TEST_REQUEST_START))
        setTimeout(() => {
            dispatch(FunctionService.request(testevalConstants.SUBMIT_TEST_REQUEST_END))
        }, 2000);
    }
}